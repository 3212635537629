
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        










.infos__share {
  @include top-dashed-line;

  display: flex;
  justify-content: center;
  margin: $spacing * 2 auto;
  padding-top: $spacing * 2;
}
